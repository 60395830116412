import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`In this course, we'll discuss several types of neural networks.  We'll begin this journey by examining the simplest form of neural network: the multilayer perceptron (MLP), which is a subtype of `}<strong parentName="p">{`feedforward neural networks`}</strong>{`.`}</p>
    <h1 {...{
      "id": "objectives",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#objectives",
        "aria-label": "objectives permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Objectives`}</h1>
    <ul>
      <li parentName="ul">{`describe the components of a simple feedforward neural network`}</li>
      <li parentName="ul">{`convert simple functions to computation graphs`}</li>
      <li parentName="ul">{`describe how a neural network learns`}</li>
    </ul>
    <h1 {...{
      "id": "getting-started",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#getting-started",
        "aria-label": "getting started permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting started`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Before reading further, `}<a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=aircAruvnKk&list=PLZHQObOWTQDNU6R1_67000Dx_ZCJB-3pi",
            "target": "_self",
            "rel": "nofollow"
          }}>{`watch the following video series from Grant Sanderson (3blue1brown)`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you're rusty on matrix multiplication, review one of the following resources:`}</p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Matrix_multiplication",
              "target": "_self",
              "rel": "nofollow"
            }}>{`https://en.wikipedia.org/wiki/Matrix_multiplication`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.khanacademy.org/math/precalculus/x9e81a4f98389efdf:matrices/x9e81a4f98389efdf:multiplying-matrices-by-matrices/v/matrix-multiplication-intro",
              "target": "_self",
              "rel": "nofollow"
            }}>{`https://www.khanacademy.org/math/precalculus/x9e81a4f98389efdf:matrices/x9e81a4f98389efdf:multiplying-matrices-by-matrices/v/matrix-multiplication-intro`}</a></li>
        </ul>
      </li>
    </ul>
    <h1 {...{
      "id": "background-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background-resources",
        "aria-label": "background resources permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background resources`}</h1>
    <p>{`The resources linked below provide helpful background on the topics covered in this lesson:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arxiv.org/pdf/1510.00726.pdf#page=1",
          "target": "_self",
          "rel": "nofollow"
        }}>{`OPTIONAL: Sections 1-3 of Yoav Goldberg's Primer on Neural Network Models for NLP`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arxiv.org/pdf/1510.00726.pdf#page=11",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Section 4 of Yoav Goldberg's Primer on Neural Network Models for NLP`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://public.parsertongue.com/readings/slp3/7.pdf",
          "target": "_self",
          "rel": "nofollow"
        }}>{`7.0 - 7.4 of Jurafsky and Martin's `}<em parentName="a">{`Speech and Language Processing`}</em></a></li>
    </ul>
    <h1 {...{
      "id": "moving-from-one-neuron-to-a-neural-network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#moving-from-one-neuron-to-a-neural-network",
        "aria-label": "moving from one neuron to a neural network permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Moving from one neuron to a neural network`}</h1>
    <p>{`In LING 539, we had an introduction to one possible building block of neural networks, logistic regression, which we can think of as a type of artificial `}<strong parentName="p">{`neuron`}</strong>{` with a particular activation function, the sigmoid.  Imagine each circle in the following diagram as a logistic regression:`}</p>
    <svg {...{
      "width": "483pt",
      "height": "432pt",
      "viewBox": "0.00 0.00 483.39 432.00",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 428)"
      }}>
        <title parentName="g">{`G`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-428 479.3869,-428 479.3869,4 -4,4"
        }}></polygon>
        <g parentName="g" {...{
          "id": "clust2",
          "className": "cluster"
        }}>
          <title parentName="g">{`cluster_hidden`}</title>
          <polygon parentName="g" {...{
            "fill": "#d3d3d3",
            "stroke": "#d3d3d3",
            "points": "113.3869,-8 113.3869,-416 363.3869,-416 363.3869,-8 113.3869,-8"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "238.3869",
            "y": "-399.4",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`hidden layers`}</text>
        </g>
        <g parentName="g" {...{
          "id": "clust3",
          "className": "cluster"
        }}>
          <title parentName="g">{`cluster_h1`}</title>
          <polygon parentName="g" {...{
            "fill": "#d3d3d3",
            "stroke": "#d3d3d3",
            "points": "121.3869,-16 121.3869,-383 202.3869,-383 202.3869,-16 121.3869,-16"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "161.8869",
            "y": "-366.4",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`layer 2 (h1)`}</text>
        </g>
        <g parentName="g" {...{
          "id": "clust4",
          "className": "cluster"
        }}>
          <title parentName="g">{`cluster_h2`}</title>
          <polygon parentName="g" {...{
            "fill": "#d3d3d3",
            "stroke": "#d3d3d3",
            "points": "274.3869,-16 274.3869,-383 355.3869,-383 355.3869,-16 274.3869,-16"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "314.8869",
            "y": "-366.4",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`layer 3 (h2)`}</text>
        </g>
        <g parentName="g" {...{
          "id": "clust1",
          "className": "cluster"
        }}>
          <title parentName="g">{`cluster_0`}</title>
          <polygon parentName="g" {...{
            "fill": "none",
            "stroke": "#ffffff",
            "points": "8,-35 8,-319 65.3869,-319 65.3869,-35 8,-35"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "36.6935",
            "y": "-302.4",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`layer 1`}</text>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "36.6935",
            "y": "-285.6",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`(input)`}</text>
        </g>
        <g parentName="g" {...{
          "id": "clust5",
          "className": "cluster"
        }}>
          <title parentName="g">{`cluster_3`}</title>
          <polygon parentName="g" {...{
            "fill": "none",
            "stroke": "#ffffff",
            "points": "411.3869,-161 411.3869,-255 472.3869,-255 472.3869,-161 411.3869,-161"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "441.8869",
            "y": "-238.4",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`layer 4`}</text>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "441.8869",
            "y": "-221.6",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`(output)`}</text>
        </g>
        {
          /* b1 */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`b1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#00008b",
            "cx": "36.6935",
            "cy": "-251",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "36.6935",
            "y": "-246.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* a12 */
        }
        <g parentName="g" {...{
          "id": "node5",
          "className": "node"
        }}>
          <title parentName="g">{`a12`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "161.3869",
            "cy": "-100",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* b1&#45;&gt;a12 */
        }
        <g parentName="g" {...{
          "id": "edge16",
          "className": "edge"
        }}>
          <title parentName="g">{`b1->a12`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M48.2008,-237.0649C70.0068,-210.6585 117.4859,-153.1628 143.4855,-121.6781"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.2387,-123.8408 149.9074,-113.9014 140.8411,-119.3836 146.2387,-123.8408"
          }}></polygon>
        </g>
        {
          /* a22 */
        }
        <g parentName="g" {...{
          "id": "node6",
          "className": "node"
        }}>
          <title parentName="g">{`a22`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "161.3869",
            "cy": "-42",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* b1&#45;&gt;a22 */
        }
        <g parentName="g" {...{
          "id": "edge17",
          "className": "edge"
        }}>
          <title parentName="g">{`b1->a22`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M46.0657,-235.291C67.6473,-199.1179 120.9783,-109.7293 146.8483,-66.3684"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "149.958,-67.9872 152.076,-57.6062 143.9466,-64.4006 149.958,-67.9872"
          }}></polygon>
        </g>
        {
          /* a32 */
        }
        <g parentName="g" {...{
          "id": "node8",
          "className": "node"
        }}>
          <title parentName="g">{`a32`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "161.3869",
            "cy": "-274",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* b1&#45;&gt;a32 */
        }
        <g parentName="g" {...{
          "id": "edge18",
          "className": "edge"
        }}>
          <title parentName="g">{`b1->a32`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M54.559,-254.2953C75.2076,-258.104 109.4385,-264.418 133.5777,-268.8705"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "133.0855,-272.3387 143.5545,-270.7108 134.3553,-265.4548 133.0855,-272.3387"
          }}></polygon>
        </g>
        {
          /* a42 */
        }
        <g parentName="g" {...{
          "id": "node9",
          "className": "node"
        }}>
          <title parentName="g">{`a42`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "161.3869",
            "cy": "-216",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* b1&#45;&gt;a42 */
        }
        <g parentName="g" {...{
          "id": "edge19",
          "className": "edge"
        }}>
          <title parentName="g">{`b1->a42`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M54.0315,-246.1334C74.7596,-240.3153 109.6997,-230.508 134.0694,-223.6677"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "135.112,-227.0104 143.7941,-220.9381 133.2203,-220.2708 135.112,-227.0104"
          }}></polygon>
        </g>
        {
          /* a52 */
        }
        <g parentName="g" {...{
          "id": "node10",
          "className": "node"
        }}>
          <title parentName="g">{`a52`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "161.3869",
            "cy": "-158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* b1&#45;&gt;a52 */
        }
        <g parentName="g" {...{
          "id": "edge20",
          "className": "edge"
        }}>
          <title parentName="g">{`b1->a52`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M51.4871,-239.9665C72.9544,-223.9556 112.9402,-194.133 138.3048,-175.2153"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "140.689,-177.8034 146.6125,-169.0192 136.504,-172.1922 140.689,-177.8034"
          }}></polygon>
        </g>
        {
          /* x1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`x1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#00008b",
            "cx": "36.6935",
            "cy": "-190",
            "rx": "20.8887",
            "ry": "20.8887"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "36.6935",
            "y": "-185.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`x1`}</text>
        </g>
        {
          /* x1&#45;&gt;a12 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`x1->a12`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M53.5102,-177.8622C75.4163,-162.051 113.8111,-134.3388 138.3955,-116.5945"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "140.7118,-119.2392 146.7719,-110.5487 136.615,-113.5632 140.7118,-119.2392"
          }}></polygon>
        </g>
        {
          /* x1&#45;&gt;a22 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`x1->a22`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M50.0378,-174.1614C72.4347,-147.5783 117.6229,-93.9439 143.0077,-63.8145"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "145.8403,-65.8845 149.6069,-55.9818 140.487,-61.3742 145.8403,-65.8845"
          }}></polygon>
        </g>
        {
          /* x1&#45;&gt;a32 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`x1->a32`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M54.0315,-201.6798C75.8449,-216.3745 113.3977,-241.672 137.8069,-258.1153"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "135.9025,-261.0524 146.1516,-263.7367 139.8134,-255.2468 135.9025,-261.0524"
          }}></polygon>
        </g>
        {
          /* x1&#45;&gt;a42 */
        }
        <g parentName="g" {...{
          "id": "edge4",
          "className": "edge"
        }}>
          <title parentName="g">{`x1->a42`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M57.0051,-194.2352C77.8312,-198.5777 110.3703,-205.3625 133.5795,-210.2018"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "133.0071,-213.6577 143.511,-212.2727 134.436,-206.8051 133.0071,-213.6577"
          }}></polygon>
        </g>
        {
          /* x1&#45;&gt;a52 */
        }
        <g parentName="g" {...{
          "id": "edge5",
          "className": "edge"
        }}>
          <title parentName="g">{`x1->a52`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M57.0051,-184.7874C77.9509,-179.4121 110.7447,-170.9963 133.9787,-165.0338"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "135.0881,-168.3625 143.9042,-162.4866 133.348,-161.5823 135.0881,-168.3625"
          }}></polygon>
        </g>
        {
          /* x2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`x2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#00008b",
            "cx": "36.6935",
            "cy": "-127",
            "rx": "20.8887",
            "ry": "20.8887"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "36.6935",
            "y": "-122.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`x2`}</text>
        </g>
        {
          /* x2&#45;&gt;a12 */
        }
        <g parentName="g" {...{
          "id": "edge6",
          "className": "edge"
        }}>
          <title parentName="g">{`x2->a12`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M57.0051,-122.6019C77.8312,-118.0924 110.3703,-111.0467 133.5795,-106.0212"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "134.4782,-109.4078 143.511,-103.8707 132.9968,-102.5663 134.4782,-109.4078"
          }}></polygon>
        </g>
        {
          /* x2&#45;&gt;a22 */
        }
        <g parentName="g" {...{
          "id": "edge7",
          "className": "edge"
        }}>
          <title parentName="g">{`x2->a22`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M54.0315,-115.1811C75.8449,-100.3115 113.3977,-74.7129 137.8069,-58.0738"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "139.8602,-60.91 146.1516,-52.3855 135.9174,-55.126 139.8602,-60.91"
          }}></polygon>
        </g>
        {
          /* x2&#45;&gt;a32 */
        }
        <g parentName="g" {...{
          "id": "edge8",
          "className": "edge"
        }}>
          <title parentName="g">{`x2->a32`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M50.2752,-143.0114C72.7768,-169.5383 117.8088,-222.6262 143.089,-252.4288"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "140.5225,-254.8139 149.6604,-260.1758 145.8607,-250.2857 140.5225,-254.8139"
          }}></polygon>
        </g>
        {
          /* x2&#45;&gt;a42 */
        }
        <g parentName="g" {...{
          "id": "edge9",
          "className": "edge"
        }}>
          <title parentName="g">{`x2->a42`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M53.7701,-139.1885C75.631,-154.7917 113.6027,-181.894 138.1008,-199.3796"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "136.2893,-202.3867 146.4621,-205.3474 140.356,-196.6891 136.2893,-202.3867"
          }}></polygon>
        </g>
        {
          /* x2&#45;&gt;a52 */
        }
        <g parentName="g" {...{
          "id": "edge10",
          "className": "edge"
        }}>
          <title parentName="g">{`x2->a52`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M57.0051,-132.0497C77.9509,-137.257 110.7447,-145.4099 133.9787,-151.1861"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "133.3551,-154.6375 143.9042,-153.6536 135.044,-147.8443 133.3551,-154.6375"
          }}></polygon>
        </g>
        {
          /* x3 */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`x3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#00008b",
            "cx": "36.6935",
            "cy": "-64",
            "rx": "20.8887",
            "ry": "20.8887"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "36.6935",
            "y": "-59.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`x3`}</text>
        </g>
        {
          /* x3&#45;&gt;a12 */
        }
        <g parentName="g" {...{
          "id": "edge11",
          "className": "edge"
        }}>
          <title parentName="g">{`x3->a12`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M56.7276,-69.784C77.7169,-75.8438 110.8201,-85.401 134.1676,-92.1416"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "133.2424,-95.5173 143.8209,-94.9285 135.1841,-88.792 133.2424,-95.5173"
          }}></polygon>
        </g>
        {
          /* x3&#45;&gt;a22 */
        }
        <g parentName="g" {...{
          "id": "edge12",
          "className": "edge"
        }}>
          <title parentName="g">{`x3->a22`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M57.284,-60.3671C78.1591,-56.6841 110.5925,-50.9618 133.7067,-46.8837"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "134.3562,-50.3233 143.5959,-45.1389 133.1399,-43.4297 134.3562,-50.3233"
          }}></polygon>
        </g>
        {
          /* x3&#45;&gt;a32 */
        }
        <g parentName="g" {...{
          "id": "edge13",
          "className": "edge"
        }}>
          <title parentName="g">{`x3->a32`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M47.3247,-81.9045C69.7114,-119.6065 121.7247,-207.2037 147.0364,-249.8318"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "144.0395,-251.6401 152.1546,-258.4516 150.0584,-248.0661 144.0395,-251.6401"
          }}></polygon>
        </g>
        {
          /* x3&#45;&gt;a42 */
        }
        <g parentName="g" {...{
          "id": "edge14",
          "className": "edge"
        }}>
          <title parentName="g">{`x3->a42`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M50.0378,-80.2667C72.5496,-107.7083 118.087,-163.2179 143.3966,-194.07"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "140.9145,-196.5628 149.9629,-202.0743 146.3264,-192.123 140.9145,-196.5628"
          }}></polygon>
        </g>
        {
          /* x3&#45;&gt;a52 */
        }
        <g parentName="g" {...{
          "id": "edge15",
          "className": "edge"
        }}>
          <title parentName="g">{`x3->a52`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M53.2518,-76.4825C75.1784,-93.0118 113.9444,-122.2355 138.6158,-140.834"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "136.6117,-143.7063 146.7038,-146.9312 140.8255,-138.1167 136.6117,-143.7063"
          }}></polygon>
        </g>
        {
          /* a13 */
        }
        <g parentName="g" {...{
          "id": "node12",
          "className": "node"
        }}>
          <title parentName="g">{`a13`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "314.3869",
            "cy": "-158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* a12&#45;&gt;a13 */
        }
        <g parentName="g" {...{
          "id": "edge21",
          "className": "edge"
        }}>
          <title parentName="g">{`a12->a13`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-106.4286C204.8829,-116.4887 256.438,-136.0324 287.9007,-147.9595"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.7613,-151.2706 297.3527,-151.5426 289.2427,-144.7251 286.7613,-151.2706"
          }}></polygon>
        </g>
        {
          /* a23 */
        }
        <g parentName="g" {...{
          "id": "node13",
          "className": "node"
        }}>
          <title parentName="g">{`a23`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "314.3869",
            "cy": "-100",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* a12&#45;&gt;a23 */
        }
        <g parentName="g" {...{
          "id": "edge26",
          "className": "edge"
        }}>
          <title parentName="g">{`a12->a23`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M179.5388,-100C205.8794,-100 254.9423,-100 286.065,-100"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.2586,-103.5001 296.2586,-100 286.2586,-96.5001 286.2586,-103.5001"
          }}></polygon>
        </g>
        {
          /* a33 */
        }
        <g parentName="g" {...{
          "id": "node14",
          "className": "node"
        }}>
          <title parentName="g">{`a33`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "314.3869",
            "cy": "-42",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* a12&#45;&gt;a33 */
        }
        <g parentName="g" {...{
          "id": "edge31",
          "className": "edge"
        }}>
          <title parentName="g">{`a12->a33`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-93.5714C204.8829,-83.5113 256.438,-63.9676 287.9007,-52.0405"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.2427,-55.2749 297.3527,-48.4574 286.7613,-48.7294 289.2427,-55.2749"
          }}></polygon>
        </g>
        {
          /* a43 */
        }
        <g parentName="g" {...{
          "id": "node15",
          "className": "node"
        }}>
          <title parentName="g">{`a43`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "314.3869",
            "cy": "-274",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* a12&#45;&gt;a43 */
        }
        <g parentName="g" {...{
          "id": "edge36",
          "className": "edge"
        }}>
          <title parentName="g">{`a12->a43`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M173.3926,-113.6535C199.9826,-143.8931 263.9956,-216.6923 295.803,-252.8654"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "293.2052,-255.2114 302.437,-260.4099 298.462,-250.589 293.2052,-255.2114"
          }}></polygon>
        </g>
        {
          /* a53 */
        }
        <g parentName="g" {...{
          "id": "node16",
          "className": "node"
        }}>
          <title parentName="g">{`a53`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "314.3869",
            "cy": "-216",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* a12&#45;&gt;a53 */
        }
        <g parentName="g" {...{
          "id": "edge41",
          "className": "edge"
        }}>
          <title parentName="g">{`a12->a53`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M175.7808,-110.913C202.4353,-131.1217 259.6665,-174.5126 291.6231,-198.7411"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.8366,-201.7789 299.9198,-205.0315 294.0657,-196.2008 289.8366,-201.7789"
          }}></polygon>
        </g>
        {
          /* a22&#45;&gt;a13 */
        }
        <g parentName="g" {...{
          "id": "edge22",
          "className": "edge"
        }}>
          <title parentName="g">{`a22->a13`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M175.7808,-52.913C202.4353,-73.1217 259.6665,-116.5126 291.6231,-140.7411"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.8366,-143.7789 299.9198,-147.0315 294.0657,-138.2008 289.8366,-143.7789"
          }}></polygon>
        </g>
        {
          /* a22&#45;&gt;a23 */
        }
        <g parentName="g" {...{
          "id": "edge27",
          "className": "edge"
        }}>
          <title parentName="g">{`a22->a23`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-48.4286C204.8829,-58.4887 256.438,-78.0324 287.9007,-89.9595"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.7613,-93.2706 297.3527,-93.5426 289.2427,-86.7251 286.7613,-93.2706"
          }}></polygon>
        </g>
        {
          /* a22&#45;&gt;a33 */
        }
        <g parentName="g" {...{
          "id": "edge32",
          "className": "edge"
        }}>
          <title parentName="g">{`a22->a33`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M179.5388,-42C205.8794,-42 254.9423,-42 286.065,-42"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.2586,-45.5001 296.2586,-42 286.2586,-38.5001 286.2586,-45.5001"
          }}></polygon>
        </g>
        {
          /* a22&#45;&gt;a43 */
        }
        <g parentName="g" {...{
          "id": "edge37",
          "className": "edge"
        }}>
          <title parentName="g">{`a22->a43`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M171.4257,-57.2222C197.4698,-96.714 267.3543,-202.6826 298.7438,-250.2798"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "295.8839,-252.3006 304.3112,-258.7218 301.7276,-248.4468 295.8839,-252.3006"
          }}></polygon>
        </g>
        {
          /* a22&#45;&gt;a53 */
        }
        <g parentName="g" {...{
          "id": "edge42",
          "className": "edge"
        }}>
          <title parentName="g">{`a22->a53`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M173.3926,-55.6535C199.9826,-85.8931 263.9956,-158.6923 295.803,-194.8654"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "293.2052,-197.2114 302.437,-202.4099 298.462,-192.589 293.2052,-197.2114"
          }}></polygon>
        </g>
        {
          /* b2 */
        }
        <g parentName="g" {...{
          "id": "node7",
          "className": "node"
        }}>
          <title parentName="g">{`b2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "161.3869",
            "cy": "-332",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "161.3869",
            "y": "-327.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* b2&#45;&gt;a13 */
        }
        <g parentName="g" {...{
          "id": "edge46",
          "className": "edge"
        }}>
          <title parentName="g">{`b2->a13`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M173.3926,-318.3465C199.9826,-288.1069 263.9956,-215.3077 295.803,-179.1346"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "298.462,-181.411 302.437,-171.5901 293.2052,-176.7886 298.462,-181.411"
          }}></polygon>
        </g>
        {
          /* b2&#45;&gt;a23 */
        }
        <g parentName="g" {...{
          "id": "edge47",
          "className": "edge"
        }}>
          <title parentName="g">{`b2->a23`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M171.4257,-316.7778C197.4698,-277.286 267.3543,-171.3174 298.7438,-123.7202"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "301.7276,-125.5532 304.3112,-115.2782 295.8839,-121.6994 301.7276,-125.5532"
          }}></polygon>
        </g>
        {
          /* b2&#45;&gt;a33 */
        }
        <g parentName="g" {...{
          "id": "edge48",
          "className": "edge"
        }}>
          <title parentName="g">{`b2->a33`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M169.8308,-315.9953C195.17,-267.9667 270.6916,-124.8212 301.2925,-66.8194"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "304.3984,-68.433 305.9691,-57.9553 298.2072,-65.1666 304.3984,-68.433"
          }}></polygon>
        </g>
        {
          /* b2&#45;&gt;a43 */
        }
        <g parentName="g" {...{
          "id": "edge49",
          "className": "edge"
        }}>
          <title parentName="g">{`b2->a43`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-325.5714C204.8829,-315.5113 256.438,-295.9676 287.9007,-284.0405"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.2427,-287.2749 297.3527,-280.4574 286.7613,-280.7294 289.2427,-287.2749"
          }}></polygon>
        </g>
        {
          /* b2&#45;&gt;a53 */
        }
        <g parentName="g" {...{
          "id": "edge50",
          "className": "edge"
        }}>
          <title parentName="g">{`b2->a53`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M175.7808,-321.087C202.4353,-300.8783 259.6665,-257.4874 291.6231,-233.2589"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "294.0657,-235.7992 299.9198,-226.9685 289.8366,-230.2211 294.0657,-235.7992"
          }}></polygon>
        </g>
        {
          /* a32&#45;&gt;a13 */
        }
        <g parentName="g" {...{
          "id": "edge23",
          "className": "edge"
        }}>
          <title parentName="g">{`a32->a13`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M175.7808,-263.087C202.4353,-242.8783 259.6665,-199.4874 291.6231,-175.2589"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "294.0657,-177.7992 299.9198,-168.9685 289.8366,-172.2211 294.0657,-177.7992"
          }}></polygon>
        </g>
        {
          /* a32&#45;&gt;a23 */
        }
        <g parentName="g" {...{
          "id": "edge28",
          "className": "edge"
        }}>
          <title parentName="g">{`a32->a23`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M173.3926,-260.3465C199.9826,-230.1069 263.9956,-157.3077 295.803,-121.1346"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "298.462,-123.411 302.437,-113.5901 293.2052,-118.7886 298.462,-123.411"
          }}></polygon>
        </g>
        {
          /* a32&#45;&gt;a33 */
        }
        <g parentName="g" {...{
          "id": "edge33",
          "className": "edge"
        }}>
          <title parentName="g">{`a32->a33`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M171.4257,-258.7778C197.4698,-219.286 267.3543,-113.3174 298.7438,-65.7202"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "301.7276,-67.5532 304.3112,-57.2782 295.8839,-63.6994 301.7276,-67.5532"
          }}></polygon>
        </g>
        {
          /* a32&#45;&gt;a43 */
        }
        <g parentName="g" {...{
          "id": "edge38",
          "className": "edge"
        }}>
          <title parentName="g">{`a32->a43`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M179.5388,-274C205.8794,-274 254.9423,-274 286.065,-274"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.2586,-277.5001 296.2586,-274 286.2586,-270.5001 286.2586,-277.5001"
          }}></polygon>
        </g>
        {
          /* a32&#45;&gt;a53 */
        }
        <g parentName="g" {...{
          "id": "edge43",
          "className": "edge"
        }}>
          <title parentName="g">{`a32->a53`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-267.5714C204.8829,-257.5113 256.438,-237.9676 287.9007,-226.0405"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.2427,-229.2749 297.3527,-222.4574 286.7613,-222.7294 289.2427,-229.2749"
          }}></polygon>
        </g>
        {
          /* a42&#45;&gt;a13 */
        }
        <g parentName="g" {...{
          "id": "edge24",
          "className": "edge"
        }}>
          <title parentName="g">{`a42->a13`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-209.5714C204.8829,-199.5113 256.438,-179.9676 287.9007,-168.0405"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.2427,-171.2749 297.3527,-164.4574 286.7613,-164.7294 289.2427,-171.2749"
          }}></polygon>
        </g>
        {
          /* a42&#45;&gt;a23 */
        }
        <g parentName="g" {...{
          "id": "edge29",
          "className": "edge"
        }}>
          <title parentName="g">{`a42->a23`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M175.7808,-205.087C202.4353,-184.8783 259.6665,-141.4874 291.6231,-117.2589"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "294.0657,-119.7992 299.9198,-110.9685 289.8366,-114.2211 294.0657,-119.7992"
          }}></polygon>
        </g>
        {
          /* a42&#45;&gt;a33 */
        }
        <g parentName="g" {...{
          "id": "edge34",
          "className": "edge"
        }}>
          <title parentName="g">{`a42->a33`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M173.3926,-202.3465C199.9826,-172.1069 263.9956,-99.3077 295.803,-63.1346"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "298.462,-65.411 302.437,-55.5901 293.2052,-60.7886 298.462,-65.411"
          }}></polygon>
        </g>
        {
          /* a42&#45;&gt;a43 */
        }
        <g parentName="g" {...{
          "id": "edge39",
          "className": "edge"
        }}>
          <title parentName="g">{`a42->a43`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-222.4286C204.8829,-232.4887 256.438,-252.0324 287.9007,-263.9595"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.7613,-267.2706 297.3527,-267.5426 289.2427,-260.7251 286.7613,-267.2706"
          }}></polygon>
        </g>
        {
          /* a42&#45;&gt;a53 */
        }
        <g parentName="g" {...{
          "id": "edge44",
          "className": "edge"
        }}>
          <title parentName="g">{`a42->a53`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M179.5388,-216C205.8794,-216 254.9423,-216 286.065,-216"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.2586,-219.5001 296.2586,-216 286.2586,-212.5001 286.2586,-219.5001"
          }}></polygon>
        </g>
        {
          /* a52&#45;&gt;a13 */
        }
        <g parentName="g" {...{
          "id": "edge25",
          "className": "edge"
        }}>
          <title parentName="g">{`a52->a13`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M179.5388,-158C205.8794,-158 254.9423,-158 286.065,-158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.2586,-161.5001 296.2586,-158 286.2586,-154.5001 286.2586,-161.5001"
          }}></polygon>
        </g>
        {
          /* a52&#45;&gt;a23 */
        }
        <g parentName="g" {...{
          "id": "edge30",
          "className": "edge"
        }}>
          <title parentName="g">{`a52->a23`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-151.5714C204.8829,-141.5113 256.438,-121.9676 287.9007,-110.0405"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.2427,-113.2749 297.3527,-106.4574 286.7613,-106.7294 289.2427,-113.2749"
          }}></polygon>
        </g>
        {
          /* a52&#45;&gt;a33 */
        }
        <g parentName="g" {...{
          "id": "edge35",
          "className": "edge"
        }}>
          <title parentName="g">{`a52->a33`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M175.7808,-147.087C202.4353,-126.8783 259.6665,-83.4874 291.6231,-59.2589"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "294.0657,-61.7992 299.9198,-52.9685 289.8366,-56.2211 294.0657,-61.7992"
          }}></polygon>
        </g>
        {
          /* a52&#45;&gt;a43 */
        }
        <g parentName="g" {...{
          "id": "edge40",
          "className": "edge"
        }}>
          <title parentName="g">{`a52->a43`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M175.7808,-168.913C202.4353,-189.1217 259.6665,-232.5126 291.6231,-256.7411"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.8366,-259.7789 299.9198,-263.0315 294.0657,-254.2008 289.8366,-259.7789"
          }}></polygon>
        </g>
        {
          /* a52&#45;&gt;a53 */
        }
        <g parentName="g" {...{
          "id": "edge45",
          "className": "edge"
        }}>
          <title parentName="g">{`a52->a53`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-164.4286C204.8829,-174.4887 256.438,-194.0324 287.9007,-205.9595"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.7613,-209.2706 297.3527,-209.5426 289.2427,-202.7251 286.7613,-209.2706"
          }}></polygon>
        </g>
        {
          /* b3 */
        }
        <g parentName="g" {...{
          "id": "node11",
          "className": "node"
        }}>
          <title parentName="g">{`b3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "314.3869",
            "cy": "-332",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "314.3869",
            "y": "-327.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* O1 */
        }
        <g parentName="g" {...{
          "id": "node17",
          "className": "node"
        }}>
          <title parentName="g">{`O1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#ff0000",
            "cx": "441.3869",
            "cy": "-187",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* b3&#45;&gt;O1 */
        }
        <g parentName="g" {...{
          "id": "edge56",
          "className": "edge"
        }}>
          <title parentName="g">{`b3->O1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M326.3348,-318.3588C348.4598,-293.0979 395.9654,-238.8592 422.4914,-208.5736"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "425.4224,-210.5393 429.3783,-200.7106 420.1566,-205.9271 425.4224,-210.5393"
          }}></polygon>
        </g>
        {
          /* a13&#45;&gt;O1 */
        }
        <g parentName="g" {...{
          "id": "edge51",
          "className": "edge"
        }}>
          <title parentName="g">{`a13->O1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M332.0457,-162.0323C353.1572,-166.8531 388.7436,-174.9791 413.5641,-180.6468"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "412.9404,-184.0944 423.4687,-182.9084 414.4988,-177.27 412.9404,-184.0944"
          }}></polygon>
        </g>
        {
          /* a23&#45;&gt;O1 */
        }
        <g parentName="g" {...{
          "id": "edge52",
          "className": "edge"
        }}>
          <title parentName="g">{`a23->O1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M329.4542,-110.3217C351.3186,-125.2996 392.044,-153.1982 417.8778,-170.8954"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "416.1113,-173.9277 426.3392,-176.6917 420.0674,-168.1527 416.1113,-173.9277"
          }}></polygon>
        </g>
        {
          /* a33&#45;&gt;O1 */
        }
        <g parentName="g" {...{
          "id": "edge53",
          "className": "edge"
        }}>
          <title parentName="g">{`a33->O1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M326.3348,-55.6412C348.4598,-80.9021 395.9654,-135.1408 422.4914,-165.4264"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "420.1566,-168.0729 429.3783,-173.2894 425.4224,-163.4607 420.1566,-168.0729"
          }}></polygon>
        </g>
        {
          /* a43&#45;&gt;O1 */
        }
        <g parentName="g" {...{
          "id": "edge54",
          "className": "edge"
        }}>
          <title parentName="g">{`a43->O1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M329.4542,-263.6783C351.3186,-248.7004 392.044,-220.8018 417.8778,-203.1046"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "420.0674,-205.8473 426.3392,-197.3083 416.1113,-200.0723 420.0674,-205.8473"
          }}></polygon>
        </g>
        {
          /* a53&#45;&gt;O1 */
        }
        <g parentName="g" {...{
          "id": "edge55",
          "className": "edge"
        }}>
          <title parentName="g">{`a53->O1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M332.0457,-211.9677C353.1572,-207.1469 388.7436,-199.0209 413.5641,-193.3532"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "414.4988,-196.73 423.4687,-191.0916 412.9404,-189.9056 414.4988,-196.73"
          }}></polygon>
        </g>
      </g>
    </svg>
    <p>{`This is one possible design a `}<strong parentName="p">{`mulilayer perceptron`}</strong>{` (MLP) can take.  The MLP depicted above has two `}<strong parentName="p">{`hidden`}</strong>{` layers (layers other than the network's input and output).  All neural networks are composed of interconnected neurons, though the particular configuration of these connections and the internal structure of each neuron may differ. All MLPs have some fixed-size input layer and one or more hidden layers each comprised of one or more neurons`}<sup parentName="p" {...{
        "id": "fnref-5"
      }}><a parentName="sup" {...{
          "href": "#fn-5",
          "className": "footnote-ref"
        }}>{`5`}</a></sup>{`. The layers are arranged sequentially (`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mtext parentName="mrow">{`input`}</mtext><mo parentName="mrow">{`→`}</mo><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mrow parentName="msub"><mi parentName="mrow">{`l`}</mi><mn parentName="mrow">{`1`}</mn></mrow></msub><mo parentName="mrow">{`→`}</mo><mo parentName="mrow">{`⋯`}</mo><mo parentName="mrow">{`→`}</mo><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mrow parentName="msub"><mi parentName="mrow">{`l`}</mi><mi parentName="mrow">{`n`}</mi></mrow></msub><mo parentName="mrow">{`→`}</mo><mtext parentName="mrow">{`output`}</mtext></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\text{input} \\rightarrow h_{l1} \\rightarrow \\dots \\rightarrow h_{ln} \\rightarrow \\text{output}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8623em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`input`}</span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`h`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3361em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.01968em"
                                }
                              }}>{`l`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}>{`⋯`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`h`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3361em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.01968em"
                                }
                              }}>{`l`}</span><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`n`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8095em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`output`}</span></span></span></span></span></span>{`). As the name suggests, the input layer is the first layer in the network. It provides the initial numerical representation of each datapoint.  `}</p>
    <h2 {...{
      "id": "properties-universal-to-mlps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#properties-universal-to-mlps",
        "aria-label": "properties universal to mlps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Properties universal to MLPs`}</h2>
    <ul>
      <li parentName="ul">{`at least one hidden layer`}</li>
      <li parentName="ul">{`fully connected`}
        <ul parentName="li">
          <li parentName="ul">{`every neuron in one layer of the network is connected to every neuron in the next layer. The output of one neuron becomes part of the input to all neurons in the next layer of the network (each of these inputs takes some learnable weight).`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Networks with at least two hidden layers (such as the one shown above) are the shallowest form of deep neural networks.`}</p>
    <h1 {...{
      "id": "defining-multilayer-perceptron",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#defining-multilayer-perceptron",
        "aria-label": "defining multilayer perceptron permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Defining multilayer perceptron`}</h1>
    <p>{`There is some ambiguity about the definition of a multilayer perceptron (MLP).`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{`  We will take it to mean a fully connected feedforward neural network with neurons exhibiting the following properties:`}</p>
    <ol>
      <li parentName="ol">{`each neuron in the network takes as input a) activations from the immediately previous layer, b) weights, and c) a bias term (might be set to zero).  `}</li>
      <li parentName="ol">{`A linear transformation`}<sup parentName="li" {...{
          "id": "fnref-3"
        }}><a parentName="sup" {...{
            "href": "#fn-3",
            "className": "footnote-ref"
          }}>{`3`}</a></sup>{` of the form `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mrow parentName="mrow"><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`W`}</mi><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`x`}</mi></mrow><mo parentName="mrow">{`+`}</mo><mi parentName="mrow" {...{
                      "mathvariant": "bold"
                    }}>{`b`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`\\mathbf{Wx} + \\mathbf{b}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.7694em",
                    "verticalAlign": "-0.0833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathbf"
                  }}>{`Wx`}</span></span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mbin"
                }}>{`+`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathbf"
                }}>{`b`}</span></span></span></span></span>{` (a `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Matrix_multiplication",
          "target": "_self",
          "rel": "nofollow"
        }}>{`matrix multiplication`}</a>{` and vector addition) is applied to the input.  `}</li>
      <li parentName="ol">{`The result is then fed into some activation function `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`ϕ`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`\\phi`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.8889em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`ϕ`}</span></span></span></span></span>{` that is typically non-linear.`}</li>
    </ol>
    <h3 {...{
      "id": "activation-functions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#activation-functions",
        "aria-label": "activation functions permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Activation functions`}</h3>
    <p>{`So far we've only discussed the sigmoid, but that's certainly not the only kind of nonlinear activation function.  In fact, using the sigmoid can lead to very flat gradients (that's a big problem which we'll cover later).`}</p>
    <p>{`In later lessons, we'll look at why we might want to use one activation fuction over another.  If you're curious about other options, you can interactively compare some commonly used activation functions using `}<a parentName="p" {...{
        "href": "https://github.com/Polarisation",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Justin Emery's`}</a>{` tool:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://uazhlt.github.io/activation-functions-visualized",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://uazhlt.github.io/activation-functions-visualized`}</a></li>
    </ul>
    <h2 {...{
      "id": "classifying-with-mlps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#classifying-with-mlps",
        "aria-label": "classifying with mlps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Classifying with MLPs`}</h2>
    <p>{`Recall that logistic regression is a `}<strong parentName="p">{`linear model`}</strong>{`. `}</p>
    <h3 {...{
      "id": "why-is-logistic-regression-linear",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#why-is-logistic-regression-linear",
        "aria-label": "why is logistic regression linear permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why is logistic regression linear?`}</h3>
    <p>{`Logistic regression includes no interaction between the feature values (ex. `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow">{`∗`}</mo><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x_{1} * x_{2}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6153em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`∗`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.5806em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`).  Of course, one can alter the input (`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`x`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span></span></span></span></span>{`) to include such information (ex. `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`[`}</mo><mi parentName="mrow">{`x`}</mi><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`]`}</mo></mrow><mo parentName="mrow">{`→`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`[`}</mo><mi parentName="mrow">{`x`}</mi><mo parentName="mrow" {...{
                      "separator": "true"
                    }}>{`,`}</mo><msup parentName="mrow"><mi parentName="msup">{`x`}</mi><mn parentName="msup">{`2`}</mn></msup><mo parentName="mrow" {...{
                      "separator": "true"
                    }}>{`,`}</mo><msup parentName="mrow"><mi parentName="msup">{`x`}</mi><mn parentName="msup">{`3`}</mn></msup><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`]`}</mo></mrow></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\left[ x \\right] \\rightarrow \\left[ x, x^{2}, x^{3} \\right]`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`[`}</span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "mclose delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`]`}</span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.2em",
                  "verticalAlign": "-0.35em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}><span parentName="span" {...{
                    "className": "delimsizing size1"
                  }}>{`[`}</span></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "mpunct"
                }}>{`,`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.1667em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`x`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.8141em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-3.063em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`2`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mpunct"
                }}>{`,`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.1667em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`x`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.8141em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-3.063em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`3`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}><span parentName="span" {...{
                    "className": "delimsizing size1"
                  }}>{`]`}</span></span></span></span></span></span></span>{`), but `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`y`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`w`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`x`}</mi><mo parentName="mrow">{`+`}</mo><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`y = \\mathbf{w}\\mathbf{x}+b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`y`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6667em",
                  "verticalAlign": "-0.0833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`wx`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`+`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{` as a model encodes no such trick.  No matter the value of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`w`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{w}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf",
                "style": {
                  "marginRight": "0.01597em"
                }
              }}>{`w`}</span></span></span></span></span>{` or `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{` selected, you're going to end up with a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Hyperplane",
        "target": "_self",
        "rel": "nofollow"
      }}><span parentName="a" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`n-1`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6667em",
                    "verticalAlign": "-0.0833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`n`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mbin"
                }}>{`−`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6444em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`1`}</span></span></span></span></span>{` dimensional hyperplane`}</a>{`.`}</p>
    <p>{`Like logistic regression, an MLP can be used to classify linearly separable data by iteratively updating its parameters to minimize prediction errors:`}</p>
    {
      /* Binomial logistic regression learns to separate data through iterative  */
    }
    <p><img parentName="p" {...{
        "src": "/92467257dd5487fae86387293beb1445/decision-boundary.gif",
        "alt": "\"We find our decision boundary by repeatedly measuring our error and updating our parameters.\"",
        "title": "We find our decision boundary by repeatedly measuring our error and updating our parameters."
      }}></img></p>
    <h3 {...{
      "id": "beyond-linear-models",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#beyond-linear-models",
        "aria-label": "beyond linear models permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Beyond linear models`}</h3>
    <p>{`Deep MLPs, however, can learn to classify data that is not linearly separable.`}</p>
    <p>{`More impressive still, under the right conditions, `}<a parentName="p" {...{
        "href": "http://neuralnetworksanddeeplearning.com/chap4.html",
        "target": "_self",
        "rel": "nofollow"
      }}>{`a neural network is a `}<strong parentName="a">{`universal approximator`}</strong>{`.  In other words, given enough data (and sufficiently expressive input), we can use a certain class of neural networks to approximate any function to an arbitrary level of precision`}</a><undefined parentName="p">{` `}<span {...{
          "id": "citation-0",
          "data-hover": ""
        }}><span parentName="span" {...{
            "className": "citation-number"
          }}>{`[1]`}</span></span>{`.`}</undefined></p>
    <p>{`We've looked at simple neural networks at a high level, but how are these implemented in practice?  In this course, we won't reinvent the wheel by implementing neural networks from scratch (though this can be an enlightening exercise!).  Instead, we'll design and train our networks using a popular, powerful, and efficient library.  Let's look at a couple of key components and features of frameworks like PyTorch.`}</p>
    <h2 {...{
      "id": "learning-our-parameters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#learning-our-parameters",
        "aria-label": "learning our parameters permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learning our parameters`}</h2>
    <p>{`Given some objective, one can imagine many ways you might optimize the parameters of neural network. For example, you might repeatedly try random sets of parameters. Alternatively, you could alter a single parameter in the network slightly and observe its effect on the loss. As you might guess, the problem with such methods is that they are extremely inefficient. These approaches quickly become infeasible as you increase the number of parameters.  Deep MLPs can have many parameters (each neuron has `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mrow parentName="msub"><mi parentName="mrow">{`l`}</mi><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msub><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mo parentName="mrow">{`+`}</mo><mn parentName="mrow">{`1`}</mn></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\vert h_{l-1}\\vert + 1`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`h`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3361em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.01968em"
                                }
                              }}>{`l`}</span><span parentName="span" {...{
                                "className": "mbin mtight"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.2083em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`+`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span></span></span></span></span>{` weights leading into it), but commonly used models in NLP today have `}<em parentName="p">{`millions`}</em>{` (sometime `}<em parentName="p">{`billions`}</em>{` and even a trillion) parameters!  `}</p>
    <p>{`Fortunately, there is an efficient method to updating these parameters (and we've already seen a form of it). Just like we saw with logistic regression`}<sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup>{`, we'll measure our error, compute our derivatives, and use gradient descent to actually update our parameters.`}</p>
    <h2 {...{
      "id": "computation-graphs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#computation-graphs",
        "aria-label": "computation graphs permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Computation graphs`}</h2>
    <p>{`Frameworks like PyTorch break down the many operations in a deep neural network into a directed acyclic computation graph.  These graphs can simply the execution of a neural network (moving information from one end to the other) by parallelizing (or even distributing) pieces of the computation.`}</p>
    <h3 {...{
      "id": "architecture",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#architecture",
        "aria-label": "architecture permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Architecture`}</h3>
    <p>{`Let's consider a simple function.  `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mover parentName="mrow" {...{
                    "accent": "true"
                  }}><mi parentName="mover">{`y`}</mi><mo parentName="mover">{`^`}</mo></mover><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`f`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`x`}</mi><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`;`}</mo><mi parentName="mrow">{`w`}</mi><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mi parentName="mrow">{`b`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`σ`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`w`}</mi><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`+`}</mo><mi parentName="mrow">{`b`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\hat{y} = f(x;w, b) = \\sigma(wx + b)`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8889em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord accent"
              }}><span parentName="span" {...{
                  "className": "vlist-t vlist-t2"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "0.6944em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-3em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "mord mathnormal",
                          "style": {
                            "marginRight": "0.03588em"
                          }
                        }}>{`y`}</span></span><span parentName="span" {...{
                        "style": {
                          "top": "-3em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "accent-body",
                          "style": {
                            "left": "-0.1944em"
                          }
                        }}><span parentName="span" {...{
                            "className": "mord"
                          }}>{`^`}</span></span></span></span><span parentName="span" {...{
                      "className": "vlist-s"
                    }}>{`​`}</span></span><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "0.1944em"
                      }
                    }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10764em"
                }
              }}>{`f`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`;`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02691em"
                }
              }}>{`w`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`σ`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02691em"
                }
              }}>{`w`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`+`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{` where `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`w`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`w`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02691em"
                }
              }}>{`w`}</span></span></span></span></span>{`, `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`x`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span></span></span></span></span>{`, and `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{` are all scalars (i.e., we just have a single input feature).  At a high-level, we could represent the architecture like this:`}</p>
    <svg {...{
      "width": "188pt",
      "height": "102pt",
      "viewBox": "0.00 0.00 188.00 102.00",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 98)"
      }}>
        <title parentName="g">{`G`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-98 184,-98 184,4 -4,4"
        }}></polygon>
        {
          /* x */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`x`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "27",
            "cy": "-76",
            "rx": "27",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "27",
            "y": "-71.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`x`}</text>
        </g>
        {
          /* z */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`z`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "153",
            "cy": "-47",
            "rx": "27",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "153",
            "y": "-42.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* x&#45;&gt;z */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`x->z`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M52.802,-70.0614C71.4028,-65.7803 96.7438,-59.9479 117.2868,-55.2197"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "118.2723,-58.5845 127.2325,-52.9306 116.7022,-51.7628 118.2723,-58.5845"
          }}></polygon>
        </g>
        {
          /* b */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`b`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "27",
            "cy": "-18",
            "rx": "27",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "27",
            "y": "-13.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* b&#45;&gt;z */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`b->z`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M52.802,-23.9386C71.4028,-28.2197 96.7438,-34.0521 117.2868,-38.7803"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "116.7022,-42.2372 127.2325,-41.0694 118.2723,-35.4155 116.7022,-42.2372"
          }}></polygon>
        </g>
      </g>
    </svg>
    <p>{`How does that translate into a computation graph? Let's look at one possible representation of the computation graph for the forward pass (`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`→`}</mo><mover parentName="mrow" {...{
                    "accent": "true"
                  }}><mi parentName="mover">{`y`}</mi><mo parentName="mover">{`^`}</mo></mover></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x \\rightarrow \\hat{y}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8889em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord accent"
              }}><span parentName="span" {...{
                  "className": "vlist-t vlist-t2"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "0.6944em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-3em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "mord mathnormal",
                          "style": {
                            "marginRight": "0.03588em"
                          }
                        }}>{`y`}</span></span><span parentName="span" {...{
                        "style": {
                          "top": "-3em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "accent-body",
                          "style": {
                            "left": "-0.1944em"
                          }
                        }}><span parentName="span" {...{
                            "className": "mord"
                          }}>{`^`}</span></span></span></span><span parentName="span" {...{
                      "className": "vlist-s"
                    }}>{`​`}</span></span><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "0.1944em"
                      }
                    }}><span parentName="span"></span></span></span></span></span></span></span></span></span>{`).`}</p>
    <h3 {...{
      "id": "forward-pass",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#forward-pass",
        "aria-label": "forward pass permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Forward pass`}</h3>
    <p>{`In the `}<strong parentName="p">{`forward pass`}</strong>{`, we compute our output (a prediction) given some input.  When training, we use that output (prediction) to compute our loss.`}</p>
    <p>{`Given the architecture above, we could imagine a computation graph like the following:`}</p>
    <svg {...{
      "width": "491pt",
      "height": "144pt",
      "viewBox": "0.00 0.00 491.24 144.11",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 140.1114)"
      }}>
        <title parentName="g">{`compgraphf`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-140.1114 487.2435,-140.1114 487.2435,4 -4,4"
        }}></polygon>
        {
          /* x */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`x`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18.0079",
            "cy": "-118.1114",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18.0079",
            "y": "-113.9114",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`x`}</text>
        </g>
        {
          /* mm */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`mm`}</title>
          <polygon parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "points": "163.0175,-116.6126 108.0151,-116.6126 108.0151,-61.6102 163.0175,-61.6102 163.0175,-116.6126"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.5163",
            "y": "-84.9114",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`u = wx`}</text>
        </g>
        {
          /* x&#45;&gt;mm */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`x->mm`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M35.6001,-113.7698C51.9965,-109.7233 76.9879,-103.5557 97.874,-98.4012"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "98.8716,-101.7601 107.7417,-95.9659 97.1944,-94.964 98.8716,-101.7601"
          }}></polygon>
        </g>
        {
          /* w */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`w`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18.0079",
            "cy": "-60.1114",
            "rx": "18.0157",
            "ry": "18.0157"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18.0079",
            "y": "-55.9114",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`w`}</text>
        </g>
        {
          /* w&#45;&gt;mm */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`w->mm`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M35.6001,-64.453C51.9965,-68.4995 76.9879,-74.6671 97.874,-79.8216"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "97.1944,-83.2588 107.7417,-82.2569 98.8716,-76.4627 97.1944,-83.2588"
          }}></polygon>
        </g>
        {
          /* sum */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`sum`}</title>
          <polygon parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "points": "301.0226,-88.1152 235.015,-88.1152 235.015,-22.1076 301.0226,-22.1076 301.0226,-88.1152"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "268.0188",
            "y": "-50.9114",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`z = u + b`}</text>
        </g>
        {
          /* mm&#45;&gt;sum */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`mm->sum`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M163.2932,-81.9839C181.0809,-77.4196 204.5312,-71.4022 224.7313,-66.2189"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "225.8321,-69.5499 234.6483,-63.6742 224.0922,-62.7696 225.8321,-69.5499"
          }}></polygon>
        </g>
        {
          /* pred */
        }
        <g parentName="g" {...{
          "id": "node6",
          "className": "node"
        }}>
          <title parentName="g">{`pred`}</title>
          <polygon parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "points": "483.3551,-110.3344 372.9091,-110.3344 372.9091,.1116 483.3551,.1116 483.3551,-110.3344"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "428.1321",
            "y": "-50.9114",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`out = sigmoid(v)`}</text>
        </g>
        {
          /* sum&#45;&gt;pred */
        }
        <g parentName="g" {...{
          "id": "edge5",
          "className": "edge"
        }}>
          <title parentName="g">{`sum->pred`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M301.1944,-55.1114C318.9844,-55.1114 341.5433,-55.1114 362.6932,-55.1114"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "362.9357,-58.6115 372.9356,-55.1114 362.9356,-51.6115 362.9357,-58.6115"
          }}></polygon>
        </g>
        {
          /* bias */
        }
        <g parentName="g" {...{
          "id": "node5",
          "className": "node"
        }}>
          <title parentName="g">{`bias`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "135.5163",
            "cy": "-21.1114",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.5163",
            "y": "-16.9114",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* bias&#45;&gt;sum */
        }
        <g parentName="g" {...{
          "id": "edge4",
          "className": "edge"
        }}>
          <title parentName="g">{`bias->sum`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M153.1116,-25.6263C171.364,-30.3099 200.5973,-37.8111 225.0095,-44.0752"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "224.2278,-47.488 234.7839,-46.5834 225.9676,-40.7076 224.2278,-47.488"
          }}></polygon>
        </g>
      </g>
    </svg>
    <p>{`Here, each square node is a function and each circular node is an input to some function (variable).  The output of each function feeds into a different function.  Frameworks like PyTorch assemble these kinds of graphs for a given function (for example, a neural network).  Commonly used operations ("ops") like matrix multiplication are distinct node types.`}</p>
    <h3 {...{
      "id": "backward-pass",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#backward-pass",
        "aria-label": "backward pass permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Backward pass`}</h3>
    <p><undefined parentName="p">{`We can use the computation graph to determine what adjustments need to be made to our parameters in order to reduce our error.  We do this by backpropagating our error through the network ~`}<span {...{
          "id": "citation-0",
          "data-hover": ""
        }}><span parentName="span" {...{
            "className": "citation-number"
          }}>{`[3]`}</span></span>{`:`}</undefined></p>
    <svg {...{
      "width": "1063pt",
      "height": "305pt",
      "viewBox": "0.00 0.00 1063.22 305.00",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 301)"
      }}>
        <title parentName="g">{`compgraphf`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-301 1059.2211,-301 1059.2211,4 -4,4"
        }}></polygon>
        <g parentName="g" {...{
          "id": "clust1",
          "className": "cluster"
        }}>
          <title parentName="g">{`cluster_backward`}</title>
          <polygon parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "points": "8,-44 8,-253 1047.2211,-253 1047.2211,-44 8,-44"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "527.6106",
            "y": "-236.4",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`computation graph (backward)`}</text>
        </g>
        {
          /* x */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`x`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "143.0157",
            "cy": "-279",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "143.0157",
            "y": "-274.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`x`}</text>
        </g>
        {
          /* mm */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`mm`}</title>
          <polygon parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "points": "289.0175,-220.5012 234.0151,-220.5012 234.0151,-165.4988 289.0175,-165.4988 289.0175,-220.5012"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "261.5163",
            "y": "-188.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`u = wx`}</text>
        </g>
        {
          /* x&#45;&gt;mm */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`x->mm`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M158.0239,-268.108C174.9224,-255.8442 202.976,-235.4848 225.6153,-219.0546"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "227.9549,-221.6813 233.9924,-212.9751 223.8433,-216.016 227.9549,-221.6813"
          }}></polygon>
        </g>
        {
          /* w */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`w`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "34.0079",
            "cy": "-175",
            "rx": "18.0157",
            "ry": "18.0157"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "34.0079",
            "y": "-170.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`w`}</text>
        </g>
        {
          /* w&#45;&gt;mm */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`w->mm`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M52.2412,-176.4426C89.3535,-179.3788 174.3369,-186.1025 223.8374,-190.0189"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "223.5867,-193.5099 233.8317,-190.8096 224.1389,-186.5317 223.5867,-193.5099"
          }}></polygon>
        </g>
        {
          /* mm&#45;&gt;w */
        }
        <g parentName="g" {...{
          "id": "edge12",
          "className": "edge"
        }}>
          <title parentName="g">{`mm->w`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#ff0000",
            "d": "M233.9747,-193.8778C197.0315,-195.0553 136.0157,-197 136.0157,-197 136.0157,-197 91.9947,-187.506 61.7893,-180.9916"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#ff0000",
            "stroke": "#ff0000",
            "points": "62.2894,-177.5191 51.7763,-178.8321 60.8136,-184.3617 62.2894,-177.5191"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "143.0157",
            "y": "-201.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#ff0000"
          }}>{`d4`}</text>
        </g>
        {
          /* sum */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`sum`}</title>
          <polygon parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "points": "515.0226,-185.0038 449.015,-185.0038 449.015,-118.9962 515.0226,-118.9962 515.0226,-185.0038"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "482.0188",
            "y": "-147.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`z = u + b`}</text>
        </g>
        {
          /* mm&#45;&gt;sum */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`mm->sum`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M289.4431,-187.8073C327.1799,-180.7906 394.8125,-168.215 439.0485,-159.9899"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "439.743,-163.4208 448.9347,-158.1516 438.4633,-156.5387 439.743,-163.4208"
          }}></polygon>
        </g>
        {
          /* sum&#45;&gt;mm */
        }
        <g parentName="g" {...{
          "id": "edge10",
          "className": "edge"
        }}>
          <title parentName="g">{`sum->mm`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#ff0000",
            "d": "M448.9101,-164.1397C413.3903,-177.1634 362.0169,-196 362.0169,-196 362.0169,-196 327.8891,-194.9813 299.4083,-194.1311"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#ff0000",
            "stroke": "#ff0000",
            "points": "299.3446,-190.6277 289.2446,-193.8277 299.1357,-197.6246 299.3446,-190.6277"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "369.0169",
            "y": "-200.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#ff0000"
          }}>{`d3`}</text>
        </g>
        {
          /* bias */
        }
        <g parentName="g" {...{
          "id": "node5",
          "className": "node"
        }}>
          <title parentName="g">{`bias`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "730.1321",
            "cy": "-70",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "730.1321",
            "y": "-65.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* sum&#45;&gt;bias */
        }
        <g parentName="g" {...{
          "id": "edge11",
          "className": "edge"
        }}>
          <title parentName="g">{`sum->bias`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#ff0000",
            "d": "M515.2676,-139.7672C546.3545,-128.3297 588.0207,-113 588.0207,-113 588.0207,-113 661.7352,-90.6955 702.9837,-78.2146"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#ff0000",
            "stroke": "#ff0000",
            "points": "704.1487,-81.5188 712.7065,-75.2726 702.1214,-74.8188 704.1487,-81.5188"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "595.0207",
            "y": "-117.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#ff0000"
          }}>{`d2`}</text>
        </g>
        {
          /* pred */
        }
        <g parentName="g" {...{
          "id": "node6",
          "className": "node"
        }}>
          <title parentName="g">{`pred`}</title>
          <polygon parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "points": "785.3551,-220.223 674.9091,-220.223 674.9091,-109.777 785.3551,-109.777 785.3551,-220.223"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "730.1321",
            "y": "-160.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`out = sigmoid(v)`}</text>
        </g>
        {
          /* sum&#45;&gt;pred */
        }
        <g parentName="g" {...{
          "id": "edge5",
          "className": "edge"
        }}>
          <title parentName="g">{`sum->pred`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M515.223,-153.7397C553.3163,-155.7357 616.7088,-159.0571 664.7982,-161.5768"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "664.6436,-165.0734 674.8131,-162.1015 665.01,-158.083 664.6436,-165.0734"
          }}></polygon>
        </g>
        {
          /* bias&#45;&gt;sum */
        }
        <g parentName="g" {...{
          "id": "edge4",
          "className": "edge"
        }}>
          <title parentName="g">{`bias->sum`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M712.9041,-75.6937C674.3599,-88.4324 580.2089,-119.5488 524.7699,-137.871"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "523.6196,-134.5649 515.223,-141.0262 525.8162,-141.2113 523.6196,-134.5649"
          }}></polygon>
        </g>
        {
          /* pred&#45;&gt;sum */
        }
        <g parentName="g" {...{
          "id": "edge9",
          "className": "edge"
        }}>
          <title parentName="g">{`pred->sum`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#ff0000",
            "d": "M674.7812,-177.8532C634.8787,-187.119 588.0207,-198 588.0207,-198 588.0207,-198 554.0951,-183.2778 524.6291,-170.4909"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#ff0000",
            "stroke": "#ff0000",
            "points": "525.8344,-167.1987 515.2676,-166.4285 523.0478,-173.6201 525.8344,-167.1987"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "595.0207",
            "y": "-202.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#ff0000"
          }}>{`d1`}</text>
        </g>
        {
          /* loss */
        }
        <g parentName="g" {...{
          "id": "node8",
          "className": "node"
        }}>
          <title parentName="g">{`loss`}</title>
          <polygon parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "points": "1039.2099,-155.9776 967.2547,-155.9776 967.2547,-84.0224 1039.2099,-84.0224 1039.2099,-155.9776"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "1003.2323",
            "y": "-115.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`Loss(y, a)`}</text>
        </g>
        {
          /* pred&#45;&gt;loss */
        }
        <g parentName="g" {...{
          "id": "edge7",
          "className": "edge"
        }}>
          <title parentName="g">{`pred->loss`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M785.398,-155.8936C835.9298,-147.5672 909.8114,-135.3934 957.2095,-127.5834"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "957.8978,-131.0173 967.1956,-125.9379 956.7596,-124.1104 957.8978,-131.0173"
          }}></polygon>
        </g>
        {
          /* y */
        }
        <g parentName="g" {...{
          "id": "node7",
          "className": "node"
        }}>
          <title parentName="g">{`y`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "876.2435",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "876.2435",
            "y": "-13.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`y`}</text>
        </g>
        {
          /* y&#45;&gt;loss */
        }
        <g parentName="g" {...{
          "id": "edge6",
          "className": "edge"
        }}>
          <title parentName="g">{`y->loss`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M890.3187,-29.3055C906.8538,-42.5868 935.0907,-65.2673 959.1896,-84.624"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "957.1925,-87.5091 967.1808,-91.0427 961.5761,-82.0516 957.1925,-87.5091"
          }}></polygon>
        </g>
        {
          /* loss&#45;&gt;pred */
        }
        <g parentName="g" {...{
          "id": "edge8",
          "className": "edge"
        }}>
          <title parentName="g">{`loss->pred`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#ff0000",
            "d": "M966.973,-132.1776C927.2917,-145.5046 869.2435,-165 869.2435,-165 869.2435,-165 831.6978,-165 795.6175,-165"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#ff0000",
            "stroke": "#ff0000",
            "points": "795.5124,-161.5001 785.5123,-165 795.5123,-168.5001 795.5124,-161.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "876.2435",
            "y": "-169.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#ff0000"
          }}>{`d0`}</text>
        </g>
      </g>
    </svg>
    <p>{`The backward pass, or `}<strong parentName="p">{`backpropagation`}</strong>{`, is an efficient method for computing the gradients of our learnable parameters.  This only ever happens during training.  The gradients tell us what direction (i.e., `}<em parentName="p">{`should we increase or decrease this parameter?`}</em>{`) to update each of our parameters to minimize our loss (i.e., to make fewer and less egregious errors).`}</p>
    <p>{`The idea is basically this:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Loop over the nodes in reverse order starting from the last node in the graph`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Compute partial derivatives for edges between pairs of nodes until we reach all of our model's parameters.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use the `}<a parentName="p" {...{
            "href": "https://simple.wikipedia.org/wiki/Chain_rule",
            "target": "_self",
            "rel": "nofollow"
          }}>{`chain rule`}</a>{` and dynamic programming to efficiently compute the gradient`}</p>
      </li>
    </ol>
    <p>{`Let's look at what partial derivative each red edge in our example backward graph represents:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`d`}</mi><mn parentName="msub">{`0`}</mn></msub><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`L`}</mi></mrow><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mtext parentName="mrow">{`out`}</mtext></mrow></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline",
                    "height": "1em"
                  }}></mspace><msub parentName="mrow"><mi parentName="msub">{`d`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mtext parentName="mrow">{`out`}</mtext></mrow><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`z`}</mi></mrow></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline",
                    "height": "1em"
                  }}></mspace><msub parentName="mrow"><mi parentName="msub">{`d`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`z`}</mi></mrow><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`b`}</mi></mrow></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline",
                    "height": "1em"
                  }}></mspace><msub parentName="mrow"><mi parentName="msub">{`d`}</mi><mn parentName="msub">{`3`}</mn></msub><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`z`}</mi></mrow><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`u`}</mi></mrow></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline",
                    "height": "1em"
                  }}></mspace><msub parentName="mrow"><mi parentName="msub">{`d`}</mi><mn parentName="msub">{`4`}</mn></msub><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`u`}</mi></mrow><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`w`}</mi></mrow></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline",
                    "height": "1em"
                  }}></mspace></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`
d_{0} = \\frac{\\partial{L}}{\\partial{\\text{out}}} \\\\[1em]
d_{1} = \\frac{\\partial{\\text{out}}}{\\partial{z}} \\\\[1em]
d_{2} = \\frac{\\partial{z}}{\\partial{b}} \\\\[1em]
d_{3} = \\frac{\\partial{z}}{\\partial{u}} \\\\[1em]
d_{4} = \\frac{\\partial{u}}{\\partial{w}} \\\\[1em]
`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`d`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`0`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.2251em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8801em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord text mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}>{`out`}</span></span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`L`}</span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline",
              "style": {
                "marginTop": "1em"
              }
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`d`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.2251em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8801em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.04398em"
                                  }
                                }}>{`z`}</span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord text mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}>{`out`}</span></span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline",
              "style": {
                "marginTop": "1em"
              }
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`d`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.2251em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8801em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`b`}</span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.04398em"
                                  }
                                }}>{`z`}</span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline",
              "style": {
                "marginTop": "1em"
              }
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`d`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.2251em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8801em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`u`}</span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.04398em"
                                  }
                                }}>{`z`}</span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline",
              "style": {
                "marginTop": "1em"
              }
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`d`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`4`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.2251em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8801em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.02691em"
                                  }
                                }}>{`w`}</span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`u`}</span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline",
              "style": {
                "marginTop": "1em"
              }
            }}></span></span></span></span></p>
    <p>{`You can read `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`z`}</mi></mrow><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`b`}</mi></mrow></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\frac{\\partial{z}}{\\partial{b}}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.2251em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8801em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`b`}</span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.04398em"
                                  }
                                }}>{`z`}</span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span>{` as the derivative of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`z`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`z`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.04398em"
                }
              }}>{`z`}</span></span></span></span></span>{` wrt to `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{`.  In other words, at what rate does `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`z`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`z`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.04398em"
                }
              }}>{`z`}</span></span></span></span></span>{` change as `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{` changes?`}</p>
    <p><strong parentName="p">{`Why don't we have an edge from `}<span parentName="strong" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`u`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`u`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.4306em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`u`}</span></span></span></span></span>{` to `}<span parentName="strong" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`x`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`x`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.4306em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span></span></span></span></span>{`?`}</strong>{`  `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`x`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span></span></span></span></span>{` is not a `}<strong parentName="p">{`parameter`}</strong>{` of the model. `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`x`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span></span></span></span></span>{` is a `}<strong parentName="p">{`constant`}</strong>{` (for a given input, the value of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`x`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span></span></span></span></span>{` will always be the same).`}</p>
    <p>{`Looking at our computation graph, we have a composition of functions.  In order to figure out how to update our parameters, we'll compute partial derivatives and use the chain rule from calculus:`}</p>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/0T0QrHO56qg?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <p>{`As an example, how would we compute the `}<strong parentName="p">{`derivative of `}<span parentName="strong" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`z`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`z`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.4306em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.04398em"
                  }
                }}>{`z`}</span></span></span></span></span>{` wrt to `}<span parentName="strong" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`w`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`w`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.4306em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span></span></span></span></span>{`?`}</strong>{`, we can use the chain rule to figure that out:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`z`}</mi></mrow><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`w`}</mi></mrow></mfrac><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`z`}</mi></mrow><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`u`}</mi></mrow></mfrac><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`u`}</mi></mrow><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∂`}</mi><mi parentName="mrow">{`w`}</mi></mrow></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`
\\frac{\\partial{z}}{\\partial{w}} = \\frac{\\partial{z}}{\\partial{u}}\\frac{\\partial{u}}{\\partial{w}}
`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.2251em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8801em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.02691em"
                                  }
                                }}>{`w`}</span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.04398em"
                                  }
                                }}>{`z`}</span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.2251em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8801em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`u`}</span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.04398em"
                                  }
                                }}>{`z`}</span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8801em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.02691em"
                                  }
                                }}>{`w`}</span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight",
                                "style": {
                                  "marginRight": "0.05556em"
                                }
                              }}>{`∂`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`u`}</span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span></p>
    <p>{`If you're beginning to freak out from the sight of derivatives, I've got good news: frameworks like PyTorch support an efficient form of `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Automatic_differentiation",
        "target": "_self",
        "rel": "nofollow"
      }}>{`automatic differentiation ("autodiff")`}</a>{` to handle this for us.  This is done through `}<em parentName="p">{`approximate`}</em>{`, rather than symbolic differentiation.`}</p>
    <h2 {...{
      "id": "further-reading",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#further-reading",
        "aria-label": "further reading permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Further reading`}</h2>
    <p>{`For a deeper look at backpropagation and automatic differentiation, see the following resources:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://colah.github.io/posts/2015-08-Backprop/",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://colah.github.io/posts/2015-08-Backprop/`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://pytorch.org/tutorials/beginner/blitz/autograd_tutorial.html",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://pytorch.org/tutorials/beginner/blitz/autograd_tutorial.html`}</a></p>
      </li>
    </ul>
    {
      /* (i.e., measure the change given **very** small ) */
    }
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/Ilg3gGewQ5U?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/tIeHLnjs5U8?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    {
      /* $$\theta$$ */
    }
    {
      /* forward pass: https://www.youtube.com/watch?v=mvUg_7LG4RE&list=PL2acInhHkjsZMi3xnDxmE2UyCvgIkDuF5&index=2 */
    }
    {
      /* updating params in logistic regression: https://www.youtube.com/watch?v=3Of_RxLInwQ&t=312s */
    }
    {
      /* `youtube: https://www.youtube.com/watch?v=XIQ-KnsAsbg` */
    }
    {
      /*`youtube: https://www.youtube.com/watch?v=YG15m2VwSjA`*/
    }
    {
      /* ### Important papers */
    }
    {
      /* - \cite{SCHMIDHUBER201585}
      <!-- - [Learning multiple layers of */
    }
    {
      /*representation ()](https://www.cs.toronto.edu/~hinton/absps/tics.pdf) */
    }
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/courses/snlp-2/pytorch-intro"
          }}>{`Learn how to implement, train, and evaluate a neural network using PyTorch`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://playground.tensorflow.org/",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Want to play around with deep MLPs? See this interactive tool from Tensorflow`}</a></p>
      </li>
    </ul>
    <h1 {...{
      "id": "references",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#references",
        "aria-label": "references permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`References`}</h1>
    <div><ol parentName="div"><li parentName="ol"><b parentName="li">{`Neural Networks and Deep Learning`}</b>{`   `}<a parentName="li" {...{
            "href": "neuralnetworksanddeeplearning.com"
          }}>{`[link]`}</a><br parentName="li"></br>{`Nielsen, M.A., 2015. Determination Press.`}</li><li parentName="ol"><b parentName="li">{`Speech and Language Processing: An Introduction to Natural Language Processing, Computational Linguistics, and Speech Recognition`}</b>{`   `}<a parentName="li" {...{
            "href": "https:// public.parsertongue.org/readings/slp3/5.pdf#page=11"
          }}>{`[link]`}</a><br parentName="li"></br>{`Jurafsky, D. and Martin, J., 2019. Pearson Prentice Hall.`}</li><li parentName="ol"><b parentName="li">{`Learning representations by back-propagating errors`}</b>{` `}<br parentName="li"></br>{`Rumelhart, D.E., Hinton, G.E. and Williams, R.J., 1986. Nature, Vol 323(6088), pp. 533--536. Springer Science and Business Media {LLC}. `}<a parentName="li" {...{
            "href": "https://doi.org/10.1038/323533a0",
            "style": {
              "textDecoration": "inherit"
            }
          }}>{`DOI: 10.1038/323533a0`}</a></li></ol></div>
    <h1 {...{
      "id": "footnote",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#footnote",
        "aria-label": "footnote permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Footnote`}</h1>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`the precursor to the MLP is the Perceptron algorithm (the `}<strong parentName="li">{`P`}</strong>{` in ML`}<strong parentName="li">{`P`}</strong>{`) which served as the neurons of the MLP.  Rather than assume the same activation function as the Perceptron, here we take a general view of a neuron by assuming it can take any activation function.  `}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>{`If you're unclear on what makes this transformation `}<strong parentName="li">{`linear`}</strong>{`, `}<a parentName="li" {...{
            "href": "https://www.3blue1brown.com/lessons/linear-transformations#what-makes-a-transformation-linear",
            "target": "_self",
            "rel": "nofollow"
          }}>{`have a look at this resource`}</a>{`.`}<a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}>{`In LING 539, we talked about minimizing our loss using gradient descent to iteratively update our parameters. As a refresher, `}<a parentName="li" {...{
            "href": "https://public.parsertongue.org/readings/slp3/5.pdf#page=11",
            "target": "_self",
            "rel": "nofollow"
          }}><undefined parentName="a">{`Section 5.4.3 of `}<span {...{
                "id": "citation-0",
                "data-hover": ""
              }}><span parentName="span" {...{
                  "className": "citation-number"
                }}>{`[2]`}</span></span></undefined></a>{` provides a walkthrough example.`}<a parentName="li" {...{
            "href": "#fnref-4",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      